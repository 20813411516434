/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanResponseModel } from '../models/boolean-response-model';
import { Int32ResponseModel } from '../models/int-32-response-model';

@Injectable({
  providedIn: 'root',
})
export class DatabaseService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation identityDatabaseCreatePost
   */
  static readonly IdentityDatabaseCreatePostPath = '/identity/Database/Create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityDatabaseCreatePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityDatabaseCreatePost$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, DatabaseService.IdentityDatabaseCreatePostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityDatabaseCreatePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityDatabaseCreatePost$Plain(params?: {
    context?: HttpContext
  }
): Observable<BooleanResponseModel> {

    return this.identityDatabaseCreatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityDatabaseCreatePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityDatabaseCreatePost$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, DatabaseService.IdentityDatabaseCreatePostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityDatabaseCreatePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityDatabaseCreatePost$Json(params?: {
    context?: HttpContext
  }
): Observable<BooleanResponseModel> {

    return this.identityDatabaseCreatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation identityDatabaseInitSeedPost
   */
  static readonly IdentityDatabaseInitSeedPostPath = '/identity/Database/InitSeed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityDatabaseInitSeedPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityDatabaseInitSeedPost$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, DatabaseService.IdentityDatabaseInitSeedPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityDatabaseInitSeedPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityDatabaseInitSeedPost$Plain(params?: {
    context?: HttpContext
  }
): Observable<BooleanResponseModel> {

    return this.identityDatabaseInitSeedPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityDatabaseInitSeedPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityDatabaseInitSeedPost$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, DatabaseService.IdentityDatabaseInitSeedPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityDatabaseInitSeedPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityDatabaseInitSeedPost$Json(params?: {
    context?: HttpContext
  }
): Observable<BooleanResponseModel> {

    return this.identityDatabaseInitSeedPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation identityDatabaseDeleteDelete
   */
  static readonly IdentityDatabaseDeleteDeletePath = '/identity/Database/Delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityDatabaseDeleteDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityDatabaseDeleteDelete$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, DatabaseService.IdentityDatabaseDeleteDeletePath, 'delete');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityDatabaseDeleteDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityDatabaseDeleteDelete$Plain(params?: {
    context?: HttpContext
  }
): Observable<BooleanResponseModel> {

    return this.identityDatabaseDeleteDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityDatabaseDeleteDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityDatabaseDeleteDelete$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, DatabaseService.IdentityDatabaseDeleteDeletePath, 'delete');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityDatabaseDeleteDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityDatabaseDeleteDelete$Json(params?: {
    context?: HttpContext
  }
): Observable<BooleanResponseModel> {

    return this.identityDatabaseDeleteDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation identityDatabaseClearDelete
   */
  static readonly IdentityDatabaseClearDeletePath = '/identity/Database/Clear';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityDatabaseClearDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityDatabaseClearDelete$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Int32ResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, DatabaseService.IdentityDatabaseClearDeletePath, 'delete');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int32ResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityDatabaseClearDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityDatabaseClearDelete$Plain(params?: {
    context?: HttpContext
  }
): Observable<Int32ResponseModel> {

    return this.identityDatabaseClearDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Int32ResponseModel>) => r.body as Int32ResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityDatabaseClearDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityDatabaseClearDelete$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Int32ResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, DatabaseService.IdentityDatabaseClearDeletePath, 'delete');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int32ResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityDatabaseClearDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityDatabaseClearDelete$Json(params?: {
    context?: HttpContext
  }
): Observable<Int32ResponseModel> {

    return this.identityDatabaseClearDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Int32ResponseModel>) => r.body as Int32ResponseModel)
    );
  }

}
