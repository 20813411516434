import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ROUTER_PATH } from 'src/app/core/constant';

const routes: Routes = [
  { path: '', redirectTo: ROUTER_PATH.HOME, pathMatch: 'full' },
  { path: ROUTER_PATH.HOME, loadChildren: () => import('src/app/pages/home/home.module').then(m => m.HomeModule) },
  { path: ROUTER_PATH.AUTH, loadChildren: () => import('src/app/pages/auth/auth.module').then(m => m.AuthModule) },
  { path: '**', redirectTo: ROUTER_PATH.HOME, pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
