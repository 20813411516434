import { LOCATION_INITIALIZED } from "@angular/common";
import { Injector } from "@angular/core";
import { Router } from "@angular/router";
import { ROUTER_PATH, STORE_KEY } from "src/app/core/constant";

export function ApplicationInitializerFactory(injector: Injector, router: Router) {
  return async () => {
    await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    const email = sessionStorage.getItem(STORE_KEY.EMAIL);
    const token = sessionStorage.getItem(STORE_KEY.TOKEN);
    if (!email || !token) {
      router.navigate([ROUTER_PATH.AUTH]);
    }
  }
}
