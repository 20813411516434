export enum STORE_KEY {
  EMAIL = 'email',
  TOKEN = 'access_token'
}

export enum ROUTER_PATH {
  HOME = 'home',
  AUTH = 'auth'
}

export enum ROLES {
  ROOTS = 'ROOTS',
  ADMINISTRATORS = 'ADMINISTRATORS'
}
