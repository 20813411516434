import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { ROUTER_PATH, STORE_KEY } from 'src/app/core/constant';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {

  constructor(private router: Router) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let req: HttpRequest<unknown>;
    if (request.url.indexOf('login') < 0) {
      req = request.clone({
        setHeaders: {
          Authorization: `Bearer ${sessionStorage.getItem(STORE_KEY.TOKEN)}`,
        },
      });
    }
    else req = request.clone();
    return next.handle(req).pipe(catchError(err => {
      console.log(err)
      if (err instanceof HttpErrorResponse) {
        switch (err.status) {
          case 401:
            sessionStorage.clear();
            this.router.navigate([ROUTER_PATH.HOME]);
            break;

          default:
            break;
        }
      }
      throw err;
    }));
  }
}
